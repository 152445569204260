import React from 'react';
import "./Home.css"
import ProjectsSection from './ProjectsSection';
import Skills from './Skills';
import { Link } from 'react-scroll';
import {easeOutQuad } from "easing-functions"

const Home = () => {
  return (
    <div className="home-page">
        <div className='background'>
          <div className="text-container">
            <span>UX/UI + WEB DEVELOPMENT</span>
            <h1>
              Collaborate with a developer who listens and cares.
            </h1>
            <p>
                Danish Web Developer based in Aarhus📍 <br />
                I specialize in creating responsive, high- <br />performance
                websites with clean code.
            </p>
            <Link
                to="projects-section"
                smooth={true}
                duration={1500}
                easing={easeOutQuad}
              >
            <button className="work-button">View My Work</button>
            </Link>
          </div>
        </div>
        <div id="projects-section">
          <ProjectsSection />
        </div>
          <Skills />
    </div>
  );
};

export default Home;